import React from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import Icon from 'src/components/icon/icon';
import { RankListSchedulePeriodEnum } from 'src/graphql-types/globalRankingTypes';
import { useOrgLevel } from 'src/utils/auth';
import { parseCronValue } from 'src/utils/parse-cron-value/parse-cron-value';

interface ColumnsProps {
  handleEditSchedule: (id: any) => void;
}

const isDateRangeSameYear = (startDate: Date, endDate: Date): boolean => {
  return startDate?.getFullYear() === endDate?.getFullYear();
};

const getNextRankingPeriod = ({ start, end, t }) => {
  const isSameYear = isDateRangeSameYear(new Date(start), new Date(end));
  const translationString = isSameYear ? 'ranklist date range' : 'ranklist date range diff year';
  return t(translationString, {
    start: moment.utc(start),
    end: moment.utc(end),
  });
};

const getSchedulePeriodFromEnum = ({ t, period }) => {
  if (!period) return t('n/a');
  if (period === RankListSchedulePeriodEnum.ONE_MONTH) return t('1 month previous');
  if (period === RankListSchedulePeriodEnum.ONE_YEAR) return t('1 year previous');
};

const useColumns = ({ handleEditSchedule }: ColumnsProps) => {
  const { t } = useTranslation();
  const { isNational } = useOrgLevel();

  const columns: DataCols<any> = [
    {
      key: 'scheduled',
      title: ' ',
      getValue: () => <Icon name="sm-clock" spacing={{ margins: { sm: 'left' } }} />,
      noPadding: true,
      sort: false,
    },
    {
      key: 'nextRun',
      title: t('next run'),
      getValue: (m) => {
        return t('ranking schedule date', {
          date: moment(m.nextRunAt).local(),
        });
      },
    },
    {
      key: 'nextRankingPeriod',
      title: t('next ranking period'),
      getValue: (m) =>
        getNextRankingPeriod({
          start: m.data?.input?.dateRange?.start,
          end: m.data?.input?.dateRange?.end,
          t,
        }),
    },
    {
      key: 'runFrequency',
      title: t('run frequency'),
      getValue: (m) => parseCronValue({ value: m.data?.frequency, t, nextRunAt: m.nextRunAt }),
    },
    {
      key: 'scheduledRankingPeriod',
      title: t('scheduled ranking period'),
      getValue: (m) => getSchedulePeriodFromEnum({ period: m.data?.period, t }),
    },
    {
      key: 'edit schedule',
      title: ' ',
      getValue: (m) => (
        <Button hide={!isNational} level="tertiary" size="sm" onClick={() => handleEditSchedule({ scheduleId: m.id })}>
          {t('edit schedule')}
        </Button>
      ),
    },
  ];

  return columns;
};

export default useColumns;
