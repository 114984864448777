import { MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import { meshGatewayClient } from 'src/apollo/client';
import { nullGuard } from 'src/components/usta-rankings/helpers';
import { getFamilyAgeRestriction } from 'src/components/usta-ranklist/usta-ranklist.utils';
import {
  StopScheduledRankListJob,
  StopScheduledRankListJobVariables,
} from 'src/graphql-types/StopScheduledRankListJob';
import { TD_RankListSchedules, TD_RankListSchedulesVariables } from 'src/graphql-types/TD_RankListSchedules';
import { StringParam, useQueryParams } from 'use-query-params';

import { TD_RANK_LIST_SCHEDULES, TD_START_SCHEDULED_RANK_LIST_JOB, TD_STOP_SCHEDULED_RANK_LIST_JOB } from '../queries';

export const useRankListSchedule = (
  options?: QueryHookOptions<TD_RankListSchedules, TD_RankListSchedulesVariables>,
) => {
  const [query] = useQueryParams({
    listType: StringParam,
    playerType: StringParam,
    familyCategory: StringParam,
    ageRestriction: StringParam,
    gender: StringParam,
    genderModifier: StringParam,
    matchFormat: StringParam,
    matchFormatType: StringParam,
    playerLevel: StringParam,
    region: StringParam,
  });

  const input = {
    ...(nullGuard(query.listType) ? { listType: query.listType } : {}),
    ...(nullGuard(query.playerType) ? { playerType: query.playerType } : {}),
    ...(nullGuard(query.familyCategory) ? { familyCategory: query.familyCategory } : {}),
    ...(nullGuard(query.ageRestriction) ? { ageRestriction: query.ageRestriction } : {}),
    ...(nullGuard(query.gender) ? { gender: query.gender } : {}),
    ...(nullGuard(query.genderModifier) ? { genderModifier: query.genderModifier } : {}),
    ...(nullGuard(query.matchFormat) ? { matchFormat: query.matchFormat } : {}),
    ...(nullGuard(query.matchFormatType) ? { matchFormatType: query.matchFormatType } : {}),
    ...(nullGuard(query.playerLevel) ? { playerLevel: query.playerLevel } : {}),
    ...(nullGuard(query.region) ? { region: query.region } : {}),
    ...getFamilyAgeRestriction(query.playerType, query.ageRestriction),
  } as any;

  return useQuery(TD_RANK_LIST_SCHEDULES, {
    client: meshGatewayClient,
    variables: {
      filter: {
        input,
      },
    },
    notifyOnNetworkStatusChange: true, // Solves refetch not triggering update
    fetchPolicy: 'network-only', // Solves refetch not triggering update
    ...options,
  });
};

export const useStopRankListSchedule = (
  options?: MutationHookOptions<StopScheduledRankListJob, StopScheduledRankListJobVariables>,
) => {
  return useMutation(TD_STOP_SCHEDULED_RANK_LIST_JOB, {
    client: meshGatewayClient,
    ...options,
  });
};

export const useStartRankListSchedule = (
  options?: MutationHookOptions<StopScheduledRankListJob, StopScheduledRankListJobVariables>,
) => {
  return useMutation(TD_START_SCHEDULED_RANK_LIST_JOB, {
    client: meshGatewayClient,
    ...options,
  });
};
